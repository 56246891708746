"use client";

import * as Sentry from "@sentry/nextjs";
import classNames from "classnames";
import Compressor from "compressorjs";
import { Upload } from "lucide-react";
import Image from "next/image";
import { useState } from "react";
import { toast } from "react-toastify";

type Props = {
  handlePhotoChange?: ({
    file,
    photoData,
  }: {
    file: File | Blob;
    photoData: string;
  }) => void;
  photoData?: string;
  disabled: boolean;
  id?: string;
};

const ImageUploader = ({
  handlePhotoChange,
  photoData,
  disabled,
  id,
}: Props) => {
  const [dragActive, setDragActive] = useState(false);

  const storePhoto = async (blob: Blob) => {
    if (!handlePhotoChange) return;

    new Compressor(blob, {
      quality: 0.2,
      success(result) {
        const reader = new FileReader();
        reader.onload = (e) => {
          handlePhotoChange({
            file: result as File,
            photoData: e.target?.result as string,
          });
        };
        reader.readAsDataURL(result);
      },
      error(err) {
        Sentry.captureException("error when compressing photo: " + err);
        console.error(err.message);
      },
    });
  };

  const onChangePicture = async (file: File | null) => {
    if (!file) return;
    if (file.size / 1024 / 1024 > 4) {
      toast.error("File size too big (max 4MB)");
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        storePhoto(file);
      };
      reader.readAsDataURL(file);
    }
  };

  if (disabled)
    return (
      <label
        htmlFor="image-upload"
        className={classNames(
          "relative flex flex-col items-center justify-center bg-primary-1/5 border-primary-5/50 rounded-md shadow-sm cursor-default h-44 mt-2",
          photoData ? "border-solid border" : "border-dashed border-[1.5px]"
        )}
      >
        <div
          className={classNames(
            "absolute text-primary-5 text-center z-[3] flex gap-4 h-full w-full flex-col items-center justify-center rounded-md px-10 transition-all",
            photoData ? "bg-white/80 opacity-0" : "bg-primary-1/10 opacity-100"
          )}
        >
          <Upload strokeWidth={1.5} className="h-7 w-7" />
          <p>Upload a photo of your problem</p>
          <span className="sr-only">Photo upload</span>
        </div>
        {photoData && (
          <Image
            alt="preview"
            src={photoData}
            className="self-start object-contain w-full h-full rounded-lg"
            width={288}
            height={192}
          />
        )}
      </label>
    );

  return (
    <>
      <label
        htmlFor={id ?? "image-upload"}
        className={classNames(
          "relative flex flex-col items-center justify-center transition-all bg-primary-1/5 border border-primary-5/50 rounded-md shadow-sm cursor-pointer h-44 group hover:bg-white/20 mt-2",
          photoData ? "border-solid border" : "border-dashed border-[1.5px]"
        )}
      >
        <div
          className="absolute z-[5] h-full w-full rounded-md"
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(true);
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);
          }}
          onDrop={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDragActive(false);

            const file = e.dataTransfer.files && e.dataTransfer.files[0];
            onChangePicture(file);
          }}
        />
        <div
          className={classNames(
            "absolute text-primary-5 text-center z-[3] flex gap-4 h-full w-full flex-col items-center justify-center rounded-md px-10 transition-all",
            dragActive && "border-[1.5px] border-primary-2",
            photoData
              ? "bg-white/80 opacity-0 hover:opacity-100 hover:backdrop-blur-md"
              : "bg-primary-1/10 opacity-100 hover:bg-gray-50"
          )}
        >
          <Upload
            strokeWidth={1.6}
            className={classNames(
              "h-6 w-6 transition-all duration-75 group-hover:scale-110 group-active:scale-95",
              dragActive ? "scale-110" : "scale-100"
            )}
          />
          <p>Upload a photo of your problem</p>
          <span className="sr-only">Photo upload</span>
        </div>
        {photoData && (
          <Image
            alt="preview"
            src={photoData}
            className="self-start object-contain w-full h-full rounded-lg"
            width={288}
            height={192}
          />
        )}
      </label>
      <input
        id={id ?? "image-upload"}
        name="image"
        type="file"
        accept="image/*"
        className="sr-only"
        onChange={(e) => onChangePicture(e.target.files && e.target.files[0])}
        onClick={(e) => (e.currentTarget.value = "")}
      />
    </>
  );
};

export default ImageUploader;
