import { SubjectKey, subjects } from "@/utils/subjects/subjects";
import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Check, ChevronsUpDown } from "lucide-react";
import { FC, Fragment } from "react";

type Props = {
  value?: SubjectKey;
  onChange: (subjectKey: SubjectKey) => void;
  error?: string;
  disabled?: boolean;
  overrideFeatureFlag?: boolean;
};

const SubjectSelector: FC<Props> = ({ value, onChange, error, disabled }) => {
  return (
    <div className="w-full max-w-xs z-20 text-neutral-8">
      <Listbox value={value} onChange={onChange}>
        <div className="relative mt-1">
          <Listbox.Button
            onClick={(e) => {
              if (disabled) e.preventDefault();
            }}
            className={classNames(
              "text-xs relative font-medium w-full rounded-lg border border-primary-5/50 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-primary-3 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-primary-3",
              disabled ? "cursor-default" : "cursor-pointer"
            )}
          >
            {value ? (
              <span className="block truncate text-primary-8">
                {subjects[value].name}
              </span>
            ) : (
              <span className="block truncate text-primary-8/40">
                Select subject
              </span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronsUpDown className="h-4 w-4" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-72 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black/5 focus:outline-none">
              {Object.entries(subjects).map(([subjectKey, subject]) => (
                <Listbox.Option
                  key={subjectKey}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active
                        ? "bg-primary-1/50 text-primary-8"
                        : "text-neutral-8"
                    }`
                  }
                  value={subjectKey}
                >
                  {({ selected }) => (
                    <div className="flex items-center justify-between">
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {subject.name}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-7">
                          <Check className="h-4 w-4" aria-hidden="true" />
                        </span>
                      )}
                      {subject.name.toLowerCase() === "economics" && (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-[8px] bg-[#2DCCA7]/20 px-1 py-[1px] font-medium rounded-sm text-[#17B897]">
                            NEW
                          </span>
                        </span>
                      )}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {error && <p className="text-red-500 mt-3">{error}</p>}
    </div>
  );
};

export default SubjectSelector;
