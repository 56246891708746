"use client";

import { Database } from "@/types/supabase";
import { CHAT_QUOTA } from "@/utils/consts";
import { SubjectKey, TopicKey, subjects } from "@/utils/subjects/subjects";
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { MessageSquareDashed, MoveRight } from "lucide-react";
import { DateTime } from "luxon";
import mixpanel from "mixpanel-browser";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import Button from "../base/Button";
import UpgradeModal from "../modal/UpgradeModal";
import ImageUploader from "./ImageUploader";
import SubjectSelector from "./SubjectSelector";
import TopicsSelector from "./TopicsSelector";
import { useSessionContext } from "@/context/useSessionContext";
import { hasFullAccess } from "@/utils/helpers";

export type FormData = {
  subjectKey?: SubjectKey;
  topicKey?: TopicKey;
  photoData?: string;
  photoUuid?: string;
  message: string;
};

type Props = {
  userId?: string;
  locked: boolean;
  handleNewChat?: (userID: string, formData: FormData) => Promise<void>;
  resetAll?: () => void;
  readonlyFormData?: FormData;
};

const QuestionForm: FC<Props> = ({
  userId,
  locked,
  handleNewChat,
  resetAll,
  readonlyFormData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: readonlyFormData,
  });
  const [sending, setSending] = useState(false);
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const { profile } = useSessionContext();

  const onSubmit = handleSubmit(async (data) => {
    if (sending || locked || !userId) return;
    setSending(true);

    if (hasFullAccess(profile ?? undefined)) {
      const chatsCreated = await supabase
        .from("chats")
        .select("*", { count: "exact", head: true })
        .eq("user_id", userId)
        .gte("created_at", DateTime.now().minus({ days: 30 }).toISO());

      const chatsCreatedCount = chatsCreated.count ?? 0;
      if (chatsCreatedCount >= CHAT_QUOTA.PRO) {
        alert(
          `You've reached your chat limit of ${CHAT_QUOTA.PRO} for this month.`
        );
        mixpanel.track("Plus user reached chat limit");
        setSending(false);
        return;
      }
    } else {
      setShowPaywallModal(true);
      setSending(false);
      mixpanel.track("Triggered paywall modal: free user tried to create chat");
      return;
    }
    setSending(false);
    handleNewChat?.(userId, data);
  });
  const supabase = createClientComponentClient<Database>();

  const watchSubjectKey = watch("subjectKey");

  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-col md:h-full gap-7 px-12 py-7 text-xs bg-white border-primary-10/50 shadow rounded-md border"
    >
      <div className="relative flex flex-col gap-1 mt-2">
        <label className="flex items-center gap-3 font-semibold tracking-wide text-primary-10">
          <span className="text-sm">1. Select a subject</span>
        </label>
        <Controller
          control={control}
          name="subjectKey"
          rules={{
            required: "Please select a subject",
          }}
          disabled={locked}
          render={({ field: { onChange, value, disabled } }) => (
            <SubjectSelector
              value={value}
              onChange={(e) => {
                setValue("topicKey", undefined);
                onChange(e);
              }}
              error={errors.subjectKey?.message}
              disabled={disabled}
            />
          )}
        />
      </div>
      <div className="relative flex flex-col gap-1 mt-2">
        <label className="flex items-center gap-3 font-semibold tracking-wide text-primary-10">
          <span className="text-sm">2. Select a topic</span>
        </label>
        <Controller
          control={control}
          name="topicKey"
          rules={{
            required: "Please select a topic",
          }}
          disabled={locked}
          render={({ field: { onChange, value, disabled } }) => (
            <TopicsSelector
              value={value}
              onChange={onChange}
              error={errors.topicKey?.message}
              disabled={disabled}
              topicKeys={
                watchSubjectKey
                  ? subjects[watchSubjectKey].topicKeys
                  : undefined
              }
            />
          )}
        />
      </div>

      <div className="relative flex flex-col gap-1 mt-2">
        <label className="flex items-center gap-3 font-semibold tracking-wide text-primary-10">
          <span className="flex items-center gap-1 text-sm">
            <span>3. Upload a photo of your problem</span>
            <span className="font-semibold tracking-normal text-neutral-8/60">
              (optional)
            </span>
          </span>
        </label>

        <Controller
          control={control}
          name="photoData"
          disabled={locked}
          render={({ field: { onChange, value, disabled } }) => (
            <ImageUploader
              disabled={disabled ?? false}
              handlePhotoChange={async (e) => {
                const { file, photoData } = e;
                const photoUUID = uuidv4();

                setValue("photoUuid", photoUUID);
                onChange(photoData);

                await supabase.storage.from("photos").upload(photoUUID, file);

                mixpanel.track("Uploaded photo", {
                  photo_id: photoUUID,
                });
              }}
              photoData={value}
            />
          )}
        />
      </div>

      <div className="relative flex flex-col gap-1 mt-2">
        <label className="flex items-center gap-3 font-semibold tracking-wide text-primary-10">
          <span className="text-sm">4. What do you want to ask?</span>
        </label>

        <textarea
          {...register("message", { required: "Message is required" })}
          aria-invalid={errors.message ? "true" : "false"}
          rows={3}
          disabled={locked}
          className="w-full p-3 rounded-lg resize-none disabled:bg-white border-primary-5/50 border placeholder:text-primary-8/40 text-primary-8 font-medium focus:outline-0 mt-2"
          placeholder="E.g How can I solve the problem in the photo?"
        />
        {errors.message && (
          <p className="mt-1 text-red-500">{errors.message.message}</p>
        )}
      </div>

      {!readonlyFormData && (
        <div className="flex justify-end gap-2 pt-2">
          {locked && !sending && (
            <Button
              type="button"
              text="New Chat"
              variant="text"
              size="md"
              onClick={() => {
                reset();
                resetAll?.();
              }}
              icon={<MessageSquareDashed className="w-4 h-4" />}
            />
          )}

          <Button
            type="submit"
            text="Start chat"
            variant="primary"
            size="lg"
            disabled={locked}
            loading={sending}
            icon={<MoveRight className="w-5 h-5" />}
          />
        </div>
      )}

      <UpgradeModal
        onOpenChange={setShowPaywallModal}
        paywalled={true}
        show={showPaywallModal}
      />
    </form>
  );
};

export default QuestionForm;
